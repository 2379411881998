.feed {
    padding: 10px;
    height: 90vh;
    overflow-y: scroll;
    display: block;
    background-color: rgb(40, 37, 53);
}

main::-webkit-scrollbar {
    width: 0.25rem; 
}   

main::-webkit-scrollbar-track {
    background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
    background: #6649b8;
}

ul, li {
    text-align: left;
    list-style: none;
}

p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: center;
}

.message {
    display: flex;
    align-items: center;
}


.sent {
    flex-direction: row-reverse;
}

.sent p {
    color: white;
    background: #0b93f6;
    align-self: flex-end;
}

.received p {
    background: #e5e5ea;
    color: black;
}