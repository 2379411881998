body {
  background-color: #282c34;
}

.App {
  position: relative;
  max-width: 728px;
  margin: 0 auto;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}